$(document).ready(function () {


  var $elem = $('#internet');
  var $elem = $('#fachgeschaefte');
  var $elem = $('#freunde');
  var $elem = $('#zeitungen');
  var $elem = $('#messen');
  var $elem = $('#prospekte');
  var $elem = $('#buecher');
  var $elem = $('#radio');

  if ($elem.attr('id')) {
    console.log($elem)

    var in_view = new Waypoint.Inview({
      element: $elem[0],
      enter: function () {


        $('.counter').each(function () {
          var $this = $(this),
            countTo = $this.attr('data-count');

          $({ countNum: $this.text() }).animate({
            countNum: countTo
          },
            {
              duration: 1000,
              easing: 'linear',
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
                //alert('finished');
              }
            });
        });



        $("#online-tools .diagramm .zeile #internet").animate({ width: '93%' }, 1000);
        $("#online-tools .diagramm .zeile #fachgeschaefte").animate({ width: '68%' }, 1000);
        $("#online-tools .diagramm .zeile #freunde").animate({ width: '29%' }, 1000);
        $("#online-tools .diagramm .zeile #zeitungen").animate({ width: '24%' }, 1000);
        $("#online-tools .diagramm .zeile #messen").animate({ width: '23%' }, 1000);
        $("#online-tools .diagramm .zeile #prospekte").animate({ width: '15%' }, 1000);
        $("#online-tools .diagramm .zeile #buecher").animate({ width: '4%' }, 1000);
        $("#online-tools .diagramm .zeile #radio").animate({ width: '3%' }, 1000);
        console.log("scroll");
      },
      exit: function () {  // optionally
        $("#online-tools .diagramm .zeile #internet").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #fachgeschaefte").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #freunde").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #zeitungen").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #messen").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #prospekte").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #buecher").animate({ width: '0' });
        $("#online-tools .diagramm .zeile #radio").animate({ width: '0' });
      }
    });
  }




});