const modalLogin = document.getElementById('modal-login')
const modalReset = document.getElementById('modal-reset')
const modalRegister = document.getElementById('modal-anmelden')
const loginButton = document.getElementById('mitgliederLogin')
const closeButton = $('.close')
const resetPassword = document.getElementById('reset--button')
const memberButton = document.getElementById('button--nomember')
const allModals = document.querySelectorAll('.modal')

memberButton.addEventListener('click', e => { 
    modalLogin.classList.remove('active')
    modalReset.classList.remove('active')
    modalRegister.classList.add('active')
})

loginButton.addEventListener('click', () => {
    modalLogin.classList.add('active')
})

closeButton.on('click', () => {
    allModals.forEach(m => { 
        m.classList.remove('active')
    })
    
    
    loginForm.reset()
    loginError.innerHTML = "";
    resetForm.reset()
    resetError.innerHTML = "";
})

resetPassword.addEventListener('click', e => {
    e.preventDefault()
    modalLogin.classList.remove('active')
    modalReset.classList.add('active')
})

const openModal = m => { 
    const modal = document.getElementById(m)
    allModals.forEach(m => { 
        m.classList.remove('active')
    })
    modal.classList.add('active')
}