// News

const contentNews = document.getElementById("news--content");


// News

// if (contentNews) {
//   let output = "";

//   axios
//     .get("https://cms.optics-network-preview.de/wp/wp-json/wp/v2/news")
//     .then(res => {
//       res.data.map(title => {
//         let image = "assets/img/placeholder.jpg";
//         let alt = "Platzhalter";
//         if (title.better_featured_image) {
//           if (title.better_featured_image.media_details.sizes.large) {
//             image =
//               title.better_featured_image.media_details.sizes.large.source_url;
//           } else {
//             image = title.better_featured_image.source_url;
//           }
//         }

//         output += `
//             <div class="news" id="${title.id}">
//             <div class="news--content">
//                 <img class="news--content--image" src='${image}' alt="${alt}">
//                 <div class="news--content--text">
//                     <h3>${title.title.rendered}</h3>
//                     <div class="newsContent">${title.content.rendered
//                         .split("<p>")
//                         .join("")
//                         .split("</p>")
//                         .join("<br><br>")}<span class="hiddenContent">${
//                         title.acf.kundenbereich
//                         }</span></div>
//                     <div class="clearfix"></div>
//                 </div>
//             </div>
//             </div>
//         `;
//       });
//       contentNews.innerHTML = output;
//       trimWords();
//     })
//     .catch(err => {
//       if (err.message.indexOf("404") > -1) {
//         contentNews.innerHTML = `<h3 class="alert">Netzwerkfehler</h3>`;
//       }
//     });
// }

// const newsStart = document.getElementById("news--start");

// if (newsStart) {
//   let newsOutput = "";
//   axios
//     .get("https://cms.optics-network-preview.de/wp/wp-json/wp/v2/news")
//     .then(res => {
//       for (let i = 0; i <= 1; i++) {
//         let imageUrl = '/assets/img/placeholder.jpg';

//         if (res.data[i].better_featured_image) {
//           if (res.data[i].better_featured_image.media_details.sizes.medium_large) { 
//             imageUrl = res.data[i].better_featured_image.media_details.sizes.medium_large.source_url
//           }
//           else if (res.data[i].better_featured_image.media_details.sizes.medium) {
//             imageUrl = res.data[i].better_featured_image.media_details.sizes.medium.source_url
            
//           }
//         }
//         newsOutput += `
//                 <a href="news#${res.data[i].id}">
//                 <div class="col-1 box" style="background-image: url(${imageUrl})">                
//                     <div class="bildtitel">
//                       <h4>${res.data[i].title.rendered}</h4>
//                       <p>${res.data[i].excerpt.rendered}</p>                    
//                   </div>                 
//                 </div>
//                 </a>
//                 `;
//       }
//       newsStart.innerHTML = newsOutput;
//     })
//     .catch(err => {
//       if (err.message.indexOf("404") > -1) {
//         newsStart.innerHTML = `<h3 class="alert">Netzwerkfehler</h3>`;
//       }
//     });
// }
const trimWords = () => {
  let numOfWordsShown = 54;
  let contentStrings;
  const newsContent = Array.from(document.querySelectorAll(".newsContent"));
  let contentShort;
  let fullContent;

  newsContent.map(p => {
    contentStrings = p.innerHTML.split(" ");
    // console.log(contentStrings)

    if (contentStrings.length > numOfWordsShown) {
      contentShort =
        contentStrings
          .slice(0, numOfWordsShown)
          .join(" ")
          .replace("<p>", "")
          .replace("</p>", "") + ' <span class="more">...</span>';
      contentShort += "<span class='read-more'>";
      contentShort += contentStrings
        .slice(numOfWordsShown)
        .join(" ")
        .replace("<p>", "")
        .replace("</p>", "");
      contentShort += "</span>";
      contentShort += `<button class="buttonOrange" onclick="
            this.closest('.newsContent').classList.toggle('open');
            this.innerHTML === 'Weiterlesen' ? this.innerHTML = 'Schliessen' : this.innerHTML = 'Weiterlesen'
            ">Weiterlesen</button>`;
    } else {
      contentShort = contentStrings.join(" ");
    }
    p.innerHTML = contentShort;
  });
};
// if (document.getElementById("news--content") || document.getElementById("lieferanten-news--content")){ 
//     trimWords()
// }