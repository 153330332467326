const showDates = (el, target) => { 
    const kategorien = document.querySelectorAll('.blog__nav--item')
    const ergebnisse = document.querySelectorAll('.seminar--row')
    kategorien.forEach(kategorie => kategorie.classList.remove('active'))
    el && el.classList.add('active')

    ergebnisse.forEach(ergebnis => { 
        ergebnis.dataset.date === target || target === '' ? ergebnis.style.display = '' : ergebnis.style.display = 'none'
    })
}

if (document.getElementById("seminare--mobile")) { 

    document.getElementById("seminare--mobile").addEventListener("change", e => {
        let value = e.target.value;
        showDates(null, value)       
    });

}