const openTab = el => { 
    const targets = document.querySelectorAll('.jahresplan--tab-content')
    const tabs = document.querySelectorAll('.jahresplan--tab-link')
    let target = "";
    tabs.forEach(t => { 
        t.classList.remove('active')
        if (t.dataset.tab === el) { 
            target = t
            t.classList.add('active')
        }
    })
    targets.forEach(t => { 
        t.classList.remove('active')
        if (t.dataset.tab === el) { 
            target = t
            t.classList.add('active')
        }
    })
    //console.log(target);    
}

window.addEventListener('click', e => { 
    console.log(e.target.parentNode.parentNode.className.indexOf('jahresplan--tabs'));
    if (e.target.parentNode.className === 'jahresplan--tabs' || e.target.parentNode.parentNode.className.indexOf('jahresplan--tabs') === 0) { 
        document.querySelector('.jahresplan--tabs').classList.toggle('active')
    }
})