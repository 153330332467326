// // Blog Posts

const openImageInOverlay = el => {
  const modal = document.getElementById('modal');
  const image = modal.querySelector('img');
  modal.classList.add('active');
  image.src = el
}

const closeModal = (modal) => {
  document.getElementById(modal).classList.remove('active')
}




const showAllPosts = el => {
  document.querySelectorAll("[data-date]").forEach(e => {
    e.style.display = "block";
    //alert(el)
  });
};

const showPostByDate = date => {
  document.querySelectorAll("[data-date]").forEach(e => {
    e.dataset.date === date
      ? (e.style.display = "block")
      : (e.style.display = "none");
  });
};

const showPostsByCategory = category => {
  document.querySelectorAll("[data-category]").forEach(e => {
    e.dataset.category === category
      ? (e.style.display = "block")
      : (e.style.display = "none");
  });
};

if (document.getElementById("blog--mobile")) { 

    document.getElementById("blog--mobile").addEventListener("change", e => {
        let value = e.target.value;
        let selector = value.split("-");
        if (selector[0] === "id") {
            showPostsByCategory(selector[1]);
        } else if (selector[0] === "date") {
            showPostByDate(selector[1]);
        } else {
            showAllPosts();
        }
    });

}


