const burger = $('#burger'),
    menu = $('#menu'),
    menuItems = $('#menu a');

burger.on('click', function () {
    burger.toggleClass('active');
    menu.toggleClass('active');
})

menuItems.on('click', function () {
    burger.removeClass('active');
    menu.removeClass('active');
})


let scrollPos = 0;
const sticky = document.getElementById('sticky')
window.addEventListener('scroll', e => {
    if (document.body.getBoundingClientRect().top > scrollPos) {
        //console.log('up')
        sticky.classList.remove('out')
    } else
        sticky.classList.add('out')
    //console.log('down')
    scrollPos = (document.body.getBoundingClientRect()).top;
})