
if (document.querySelector('.logo--image.sliding')) {
    
    const logo = document.querySelectorAll('.logo--image.sliding')
    const texte = document.querySelectorAll('.logo--text')
    let slideIndex = 0;

    logo[0].classList.add('active')
    texte[0].classList.add('active')
    
    if (texte.length > 0) {
    const changeSlides = () => {
            slideIndex < texte.length ? slideIndex++ : slideIndex = 1
            const currLogo = document.querySelector('.l' + slideIndex)
            texte.forEach(text => text.style.display = "none")
            logo.forEach(logo => logo.classList.remove('active'))
            currLogo.style.display = "block"
            logo[slideIndex - 1].classList.add('active')
            console.log(slideIndex)
    
        }
        
        let intSlider = setInterval(changeSlides, 3000)
        
        logo.forEach((l, index) => {
            l.addEventListener('click', (e) => {
                e.preventDefault()
                clearInterval(intSlider)
                let target = l.dataset.target
                logo.forEach(l => l.classList.remove('active'))
                l.classList.add('active')
                texte.forEach(t => t.style.display = "none")
                document.getElementById(target).style.display = "block"
                slideIndex = index
                //setInterval(changeSlides, 3000)
            })
        })
    }
}