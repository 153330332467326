// Daten Lieferanten News
const contentJahresplan = document.getElementById(
  "jahresplan--content"
);

// Lieferanten News
if (contentJahresplan) {
  let outputJP = "";
  let outputJPloggedOut = "<div class='jahresplan--tabs'>";
  let loggedOutContent = '';
  let index = 1;

  axios
    .get(
      "https://opticsnetwork.de/cms/wp/wp-json/wp/v2/jahreswerbeplan"
    )
    .then(res => {
      res.data.map(title => {
        let image = "assets/img/placeholder.jpg";
        if (title.better_featured_image.source_url) {
          if (title.better_featured_image.media_details.sizes.large) {
            image =
              title.better_featured_image.media_details.sizes.large.source_url;
          } else {
            image = title.better_featured_image.source_url;
          }
        }
        let newTitle = '';

        switch (title.title.rendered) { 
          case 'Januar':
            newTitle = 'Jan'
            break;
          case 'Februar / März':
            newTitle = 'Feb / Mär'
            break;
          case 'April / Mai / Juni':
            newTitle = 'Apr / Mai / Jun'
            break;
          case 'Juli / August':
            newTitle = 'Jul / Aug'
            break;
          case 'September':
            newTitle = 'Sep'
            break;
          case 'Oktober / November':
            newTitle = 'Okt / Nov'
            break;
          case 'Dezember':
            newTitle = 'Dez'
            break;
        }
           


        outputJP += `
                <div class="jahresplan--element">
                  <img class="jahresplan--element-image" src="${image}"  alt="${title.better_featured_image.alt_text}">
                  <div class="jahresplan--element-content">
                    <h3>${title.title.rendered}</h3>
                    <div class="jahresplan--element-content-text">${title.content.rendered}</div>
                    <a class="buttonOrange" onclick="document.getElementById('modal-werbeplan').classList.add('active')">Mehr Informationen</a>
                  </div>
                </div>
                    `;
        outputJPloggedOut += `
          <div class="jahresplan--tab-link ${index === 1 ? 'active' : ''}" data-tab='${title.slug}' onclick="openTab('${title.slug}')">
            <h3>${newTitle}</h3>
          </div>
        `;
        loggedOutContent += `
          <div class="jahresplan--tab-content  ${index === 1 ? 'active' : ''}" data-tab='${title.slug}'>
            <img class="jahresplan--tab-image" src="${image}"  alt="${title.better_featured_image.alt_text}">
            <a class="buttonOrange" onclick="document.getElementById('modal-login').classList.add('active')" >Mehr Informationen</a>
          </div>
        `;
        index++
      });
      outputJPloggedOut += `</div>${loggedOutContent}`

      auth.onAuthStateChanged(user => {
        if (user) {
          contentJahresplan.innerHTML = outputJP;
        } else { 
          contentJahresplan.innerHTML = outputJPloggedOut;
        }
      })
      
    })
    .catch(err => {
      if (err.message.indexOf("404") > -1) {
        contentJahresplan.innerHTML = `<h3 class="alert">Netzwerkfehler</h3>`;
      }
    });
}


