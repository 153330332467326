// Login
const loginForm = document.querySelector("#login-form");
const resetForm = document.querySelector("#reset-form");
const loginError = document.getElementById("error-message");
const resetError = document.getElementById("error-message-reset");
const logout = document.getElementById("mitgliederLogout");
const login = document.getElementById("mitgliederLogin");
const preise = $(".priceContent");
const hiddenContent = $(".hiddenContent");
loginForm.addEventListener("submit", e => {
  e.preventDefault();
  // Daten
  const email = loginForm["login-email"].value;
  const password = loginForm["login-password"].value;

  auth
    .signInWithEmailAndPassword(email, password)
    .then(cred => {
      //   console.log(cred.user);
      hiddenContent.hide()
      modalLogin.classList.remove("active");
      loginForm.reset();
      loginError.innerHTML = "";
      window.location.reload();
    })
    .catch(err => {
      let error;
      switch (err.message) {
        case "There is no user record corresponding to this identifier. The user may have been deleted.":
          error = "Kein Nutzer mit diesen Zugangsdaten gefunden";
          break;
        case "The password is invalid or the user does not have a password.":
          error = "Ungültiges Passwort";
          break;
        default:
          error = err.message;
      }
      loginError.innerHTML = `${error}`;
    });
});

// Listen for Auth Change
auth.onAuthStateChanged(user => {
  if (user) {
    logout.innerHTML = `<img width="18" src="assets/img/icons/icon-signout.svg">Abmelden`;
    logout.style.display = "flex";
    login.style.display = "none";
    $("body").addClass("user");
  } else {
    console.log($(".hiddenContent"));
    logout.innerHTML = ``;
    logout.style.display = "none";
    preise.html("Bitte melden Sie sich an, um die Preise zu sehen");
    $("body").removeClass("user");
    setTimeout(() => {
      $(".hiddenContent").html("");
    }, 1000);
  }
});

// Logout
logout.addEventListener("click", e => {
  e.preventDefault();
  auth.signOut().then(() => {
    console.log("Abgemeldet");
    login.style.display = "flex";
    logout.style.display = "none";
    window.reload;
  });
});

// Passwort zurücksetzen
resetForm.addEventListener("submit", e => {
  e.preventDefault();
  let email = resetForm["email"].value;
  auth
    .sendPasswordResetEmail(email)
    .then(() => {
      resetForm.innerHTML = `<p class="reset-confirmation">Sie erhalten in Kürze eine E-Mail mit dem Link zum Zurücksetzen Ihres Passworts</p>`;
    })
    .catch(
      err =>
        (resetError.innerHTML = `Kein Nutzer mit der E-Mail Adresse ${email} gefunden!`)
    );
});
