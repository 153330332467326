auth.onAuthStateChanged(user => {
    if (user) {
        const gridItem = document.querySelectorAll('.grid--item')
        const gridOverlay = document.querySelectorAll('.grid--overlay')
        const closeOVerlay = document.querySelectorAll('.close--overlay')
        const kontaktButton = document.querySelectorAll('.grid--overlay .buttonOrange')

        gridItem.forEach(i => {
            i.addEventListener('click', e => {
                gridOverlay.forEach(o => o.classList.remove('active'))
                const target = i.dataset.aktion
                gridOverlay.forEach(o => {
                    if (o.dataset.overlay === target) {
                        o.classList.add('active')
                    }
                })
            })
        })

        closeOVerlay.forEach(x => {
            x.addEventListener('click', () => gridOverlay.forEach(o => o.classList.remove('active')))
        })

        kontaktButton.forEach(btn => { 
            btn.addEventListener('click', e => { 
                openModal('modal-aktionsfinder')
            })
        })
    }

    else { 
        const gridItem = document.querySelectorAll('.grid--item')
        gridItem.forEach(i => { 
            i.addEventListener('click', e => { 
                openModal('modal-login')
            })
        })
    }
  })