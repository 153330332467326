// Daten Online Experten zeigen

const contentHeader = document.getElementById('header--slider--content')

if (contentHeader) {
    // Startseite Online-Tools
    let outputHeader = ''
    const slides = document.querySelectorAll('.slide')
    const numOfSlides = slides.length
    let sliderIndex = 1
    let bullets = '<div class="bullets">';
    for (let i = 1; i <= slides.length; i++) {
        bullets += `<div data-slide="${i}" class="bullet"></div>`
    }
    bullets += '</div>'
    contentHeader.insertAdjacentHTML('beforeend', bullets)

    const bulletsList = document.querySelectorAll('.bullet')
    bulletsList.forEach(b => {
        b.classList.remove('active')
        b.addEventListener('click', e => {
            bulletsList.forEach(bl => bl.classList.remove('active'))
            e.target.classList.add('active')
            sliderIndex = e.target.dataset.slide
            slides.forEach(slide => {
                slide.style.left = (sliderIndex - 1) * -100 + "%";
            })
        })
    })
    bulletsList[sliderIndex - 1].classList.add('active')
    const slideShow = () => {
        bulletsList.forEach(b => {
            b.classList.remove('active')
        })
        bulletsList[sliderIndex - 1].classList.add('active')

        slides.forEach(slide => {
            slide.style.left = (sliderIndex - 1) * -100 + "%"
        })
        sliderIndex < numOfSlides ? sliderIndex++ : sliderIndex = 1
    }
    setInterval(slideShow, 8000)
    const showSlide = (i) => {
        sliderIndex = i;
    }
}